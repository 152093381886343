<template>
    <div class=container>
<h1> Radiometric Calibration </h1> <h3> <em> Estimating Radiance from Pixel Values </em> </h3>
<hr>
<p>
The file <a href="https://cave.cs.columbia.edu/old/software/curet/data/acal.dat"> acal.dat</a>
has the calibration parameters which translate average
pixel values into radiance values. This file has the following format
</p><pre>1 gain offset
2 gain offset
.
.
.
61 gain offset
</pre>
where the first number in the row is the sample number
and the subsequent numbers in the row
are the gain and offset used to transform pixel values into radiance
values in the following manner: <br> <i> radiance = gain*pixel + offset </i>. <br>
The term <i> radiance</i> the total radiance
 in watts/(steridian m^2) summed over the wavelength range 380nm-780nm.  
The term <i> pixel </i> is taken as the average of the R/G/B pixel values.
<p></p>
<p>
A similar file <a href="https://cave.cs.columbia.edu/old/software/curet/data/ccal.dat"> ccal.dat</a> has the color calibration parameters
in the following format:
</p><pre>1 Rgain Roffset Ggain Goffset Bgain Boffset
2 Rgain Roffset Ggain Goffset Bgain Boffset
.
.
.
61 Rgain Roffset Ggain Goffset Bgain Boffset
</pre>
These color calibration parameters are used
to convert R/G/B pixel values into R/G/B radiance
in the following manner:<br>
<i> R_radiance = Rgain*R_pixel + Roffset </i>  <br>
<i> G_radiance = Ggain*G_pixel + Goffset </i> <br>
<i> B_radiance = Bgain*B_pixel + Boffset </i> <br>
The terms <i>R_radiance</i>,<i>G_radiance</i>,<i>B_radiance</i>
are the guassian weighted average radiance over the wavelength range 380nm-780nm,
where the guassian weights are the estimated R/G/B sensitivity curves of
the video camera.
<p></p>

<hr>
The brightness of the lamp is also estimated so that
BRDF can be obtained from the radiance values.
This estimation was done by recording the radiance
from a Kodak 18% gray card using a photometer.
During the experiments 3 lamps were used (due
to the limited lifetime of each lamp).
The following table lists the three lamps along
with the samples that were measured with that lamp and
the estimate brightness E0.
<pre>Lamp_Number      Samples_Measured        E0
    1                  1-8             9.56379
    2                  9-44            57.664
    3                  45-61           13.0799
</pre>
<hr>
<router-link to="/repository/CURET/btfm">  [Return to BTF Measurements] </router-link>


    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>